import { BreakpointObserver } from "@angular/cdk/layout";
import {
  ConnectionPositionPair,
  Overlay,
  OverlayRef,
} from "@angular/cdk/overlay";
import { TemplatePortal } from "@angular/cdk/portal";
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";

@Component({
  selector: "app-search-box",
  templateUrl: "./search-box.component.html",
  styleUrls: ["./search-box.component.scss"],
})
export class SearchBoxComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() searchBoxDisabled = true;
  // @Input() isOpen = false;
  @Input() searchTemplate: TemplateRef<any>;
  @Input() public placeholder: string = "Search";
  @Input() public defaultSearch = "";
  @Input() public searchParams: any;
  @Output("onSearch") onSearch: EventEmitter<any> = new EventEmitter();

  isOpenNow = false;
  desktopObserver: any = {};
  tabletObserver: any = {};
  responsiveType = "tablet";
  @Input()
  set isOpen(isOpen: boolean) {
    this.isOpenNow = isOpen || false;
    // // console.log(this.isOpenNow);
  }
  get isOpen() {
    return this.isOpenNow;
  }

  search = {
    text: "",
  };
  currentTemplate: TemplateRef<any>;

  @ViewChild("formGroup", { static: false }) formGroup: ElementRef;
  private overlayRef!: OverlayRef;

  constructor(
    private overlay: Overlay,
    private vcr: ViewContainerRef,
    public breakpointObserver: BreakpointObserver
  ) {
    this.desktopObserver = breakpointObserver
      .observe(["(min-width: 1170px)"])
      .subscribe((result) => {
        if (result.matches) {
          this.responsiveType = "desktop";
        }
      });
    this.tabletObserver = breakpointObserver
      .observe(["(max-width: 1169px)"])
      .subscribe((result) => {
        if (result.matches) {
          this.responsiveType = "tablet";
        }
      });
  }
  ngOnDestroy(): void {
    this.desktopObserver.unsubscribe();
    this.tabletObserver.unsubscribe();
    // throw new Error("Method not implemented.");
  }

  ngOnInit(): void { }
  ngAfterViewInit() {
    this.createOverlay();
  }
  ngOnChanges(changes: SimpleChanges) {
    // // console.log(changes);
  }

  doClear() {
    this.search.text = "";
    this.searchParams[this.defaultSearch] = this.search.text;
    this.onSearch.next(this.searchParams);
  }
  doClose() {
    // // console.log(this.isOpen);
    if (this.isOpen) {
      this.detachOverlay();
      // this.isOpen = false;
    }
    // // console.log(this.isOpen);
    // this.isOpen = false;
  }

  onSubmit(l) {
    if (l.valid) {
      this.searchParams[this.defaultSearch] = this.search.text;
      this.onSearch.next(this.searchParams);
    }
  }
  doInput() {
    if (this.search.text.length === 0) {
      this.searchParams[this.defaultSearch] = this.search.text;
      this.onSearch.next(this.searchParams);
    }
  }

  createOverlay() {
    let position = [
      new ConnectionPositionPair(
        { originX: "center", originY: "bottom" },
        { overlayX: "center", overlayY: "top" }
      ),
      new ConnectionPositionPair(
        { originX: "center", originY: "top" },
        { overlayX: "center", overlayY: "bottom" }
      ),
    ];
    // // console.log(this.responsiveType);
    if (this.responsiveType === "desktop") {
      position = [
        new ConnectionPositionPair(
          { originX: "end", originY: "bottom" },
          { overlayX: "end", overlayY: "top" }
        ),
        new ConnectionPositionPair(
          { originX: "end", originY: "top" },
          { overlayX: "end", overlayY: "bottom" }
        ),
      ];
    }

    const scrollStrategy = this.overlay.scrollStrategies.reposition();
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.formGroup.nativeElement)
      .withPositions(position)
      .withPush(false);
    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy,
      hasBackdrop: true,
      backdropClass: "bg-transparent",
    });
    this.overlayRef.backdropClick().subscribe((_) => this.detachOverlay());
  }

  attachOverlay(): void {
    // this.isOpen = true;
    if (!this.overlayRef.hasAttached()) {
      const periodSelectorPortal = new TemplatePortal(
        this.searchTemplate,
        this.vcr
      );
      this.isOpen = true;
      this.overlayRef.attach(periodSelectorPortal);
    }
  }

  private detachOverlay(): void {
    // this.isOpen = false;
    if (this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
      this.isOpen = false;
    }
  }
}
