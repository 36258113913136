<div class="form-group">
    <div class="d-flex align-items-center pb-1" *ngIf="label !== ''">
        <label *ngIf="label !== ''" class="lh16">{{label}} <span class="text-danger" *ngIf="mandatory">*</span>
        </label>
        <div class="ml-auto text-danger lh15" *ngIf="errorText !== '' && value !== ''">
            <small>{{errorText}}</small>
        </div>
        <div class="ml-auto text-danger lh15" *ngIf="value === '' && errorTrueNow && mandatory">
            <small>mandatory</small>
        </div>
    </div>
    <div class="input-group">
        <input autocomplete="none" type="text" class="form-control pr28px" [(ngModel)]="value" name="{{name}}"
            placeholder="{{placeholder}}" [ngClass]="{'border-danger': value === '' && errorTrueNow}"
            [disabled]="btnClickedNow" (blur)="blured()" #inputbox (keydown)="checkenter($event)" appNumberOnly
            [max]="max" [limit]="limit" [decimal]="decimal">
        <div class="input-group-append in-1" *ngIf="value && !btnClickedNow">
            <a mat-button tabIndex="-1" class="i-btn" (click)="doClear()" matTooltip="Clear" matTooltipPosition="right">
                <ion-icon name="close-outline"></ion-icon>
            </a>
        </div>
    </div>
    <!-- <div class="input-group">
        <input autocomplete="none" type="text" class="form-control" [(ngModel)]="value" name="{{name}}"
            placeholder="{{placeholder}}" [ngClass]="{'border-danger': errorTrueNow}"
            [disabled]="btnClickedNow" (blur)="blured()" #inputbox (keydown)="checkenter($event)" appNumberOnly [limit]="limit" [decimal]="decimal" [max]="max">
        <div class="input-group-append in-1" *ngIf="value && !btnClickedNow">
            <a mat-button class="i-btn" (click)="value = ''" matTooltip="Clear" matTooltipPosition="right"
                >
                <ion-icon name="close-outline"></ion-icon>
            </a>
        </div>
    </div> -->
</div>