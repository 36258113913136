<div class="form-group">
    <div class="d-flex align-items-center h100 pb-1" *ngIf="label !== ''">
        <label for="" *ngIf="label !== ''" class="lh16">{{label}} <span class="text-danger"
                *ngIf="mandatory">*</span></label>
        <div class="ml-auto text-danger lh15" *ngIf="value === '' && errorTrueNow">
            <small class="h16">mandatory</small>
        </div>
    </div>
    <div class="input-group">

        <input type="text" class="form-control" [(ngModel)]="xvalue.date" name="{{name}}" placeholder="{{placeholder}}"
            [min]="minDateNow" [max]="maxDateNow"
            [ngClass]="{'border-danger': value === '' && errorTrueNow ,'required': requiredtrue === 'true'}"
            [matDatepicker]="picker" (input)="checkDate($event)" #inputbox (focus)="doFocus()"
            (dateChange)="dateSelected()" readonly (click)="picker.open()" [disabled]="btnClickedNow">

        <div class="input-group-append z5" *ngIf="!btnClickedNow">
            <ng-container *ngIf="value === ''|| value === null">
                <a mat-button class="i-btn mat-grey" (click)="picker.open()" tabIndex="-1"
                    [ngClass]="{'pe-none': btnClickedNow }">
                    <ion-icon name="calendar-outline"></ion-icon>
                </a>
            </ng-container>
            <ng-container *ngIf="value !== ''">
                <a mat-button class="i-btn mat-grey" tabIndex="-1" (click)="doClear()">
                    <ion-icon name="close-outline"></ion-icon>
                </a>
            </ng-container>
        </div>
        <mat-datepicker #picker disabled="false">
        </mat-datepicker>
    </div>
</div>