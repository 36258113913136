import { Pipe, PipeTransform } from "@angular/core";
import { format, isMatch, parse } from "date-fns";
import { AppConfig } from "../../config/app.config";
import { AppSettingsService } from "../../services/app-settings/app-settings.service";

@Pipe({
  name: "appDate",
})
export class AppDatePipe implements PipeTransform {
  constructor(public appSetting: AppSettingsService) { }
  transform(value: any, returnformat?: any, xformat?: any): any {
    let defaultDateServerFormat = this.appSetting.environment.serverDateFormat;
    let defaultDateServerFormatWithTime =
      this.appSetting.environment.serverDateFormatWithTime;
    let formatedDate = "";
    // // console.log(value);
    if (!value) {
      return "";
    }
    let yformat = "",
      xReturnformat = "";
    xformat
      ? (yformat = xformat)
      : (yformat = this.appSetting.environment.serverDateFormat);
    returnformat
      ? (xReturnformat = returnformat)
      : (xReturnformat = this.appSetting.environment.dateViewFormat);
    // // console.log(yformat, xReturnformat);
    if (isMatch(value, yformat)) {
      // // console.log(isMatch(value, yformat));
      let dt = parse(value, yformat, new Date());
      formatedDate = format(dt, xReturnformat);
    }
    if (isMatch(value, defaultDateServerFormatWithTime)) {
      if (returnformat === "datetime") {
        xReturnformat = this.appSetting.environment.dateViewFormatWithTime;
      }
      let dt = parse(value, defaultDateServerFormatWithTime, new Date());
      formatedDate = format(dt, xReturnformat);
      if (this.appSetting.environment.utcTime) {
        let year = parseFloat(format(dt, 'yyyy'));
        let month = parseFloat(format(dt, 'MM')) - 1;
        let date = parseFloat(format(dt, 'dd'));
        let hour = parseFloat(format(dt, 'HH'));
        let minutes = parseFloat(format(dt, 'mm'));
        let utcTime = new Date(Date.UTC(year, month, date, hour, minutes));
        formatedDate = format(utcTime, xReturnformat);
      }
    }
    // // console.log(formatedDate);
    return formatedDate;
  }
}
