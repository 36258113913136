<div class="d-flex align-items-center">
    <div>
        <ng-container *ngIf="!searchBoxDisabled">
            <form #l="ngForm" (ngSubmit)="onSubmit(l)" autocomplete="off">
                <div class="d-flex align-items-center">
                    <div class="form-group pointer">
                        <div class="input-group">
                            <div class="input-group-prepend z-2">
                                <a class="i-btn" disabled>
                                    <ion-icon name="search-outline" class="mt-0"></ion-icon>
                                </a>
                            </div>
                            <input type="text" class="form-control" placeholder="{{placeholder}}"
                                [(ngModel)]="search.text" name="searchText" required (input)="doInput()">

                            <div class="input-group-append">
                                <div class="d-flex align-items-center">
                                    <a mat-button class="i-btn" (click)="isOpen = !isOpen" (click)="doClear()"
                                        *ngIf="search.text !== ''">
                                        <ion-icon name="close-outline"></ion-icon>
                                    </a>
                                    <a mat-button class="i-btn ml-auto" (click)="isOpen = !isOpen">
                                        <ion-icon name="chevron-down-outline"></ion-icon>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="submit" mat-raised-button color="accent" class="ml-1 mw-auto">
                        Search
                    </button>
                </div>
            </form>
        </ng-container>
        <ng-container *ngIf="searchBoxDisabled">
            <div class="form-group pointer search-boxes" (click)="attachOverlay()" matRipple #formGroup>
                <div class="input-group">
                    <div class="input-group-prepend z-2">
                        <a class="i-btn" disabled>
                            <ion-icon name="search-outline" class="mt-0"></ion-icon>
                        </a>
                    </div>
                    <div type="text" class="form-control form-control-div"><span
                            class="placeholder">{{placeholder}}</span></div>
                </div>
            </div>
        </ng-container>
    </div>
</div>