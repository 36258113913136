import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatRippleModule } from "@angular/material/core";
import { MatButtonModule } from "@angular/material/button";
import { FormInputModule } from "../form-input/form-input.module";
import { MatChipsModule } from "@angular/material/chips";
import { EmptyStateModule } from "../empty-state/empty-state.module";
import { MatMenuModule } from "@angular/material/menu";
import { ActionBtnComponent } from "./action-btn/action-btn.component";
import { PermissionModule } from "../permission/permission.module";
@NgModule({
  declarations: [ActionBtnComponent],
  imports: [
    CommonModule,
    MatRippleModule,
    MatMenuModule,
    MatButtonModule,
    FormInputModule,
    MatChipsModule,
    EmptyStateModule,
    PermissionModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [ActionBtnComponent],
})
export class TemplateModule {}
