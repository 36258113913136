import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { FormsModule } from "@angular/forms";
import { InputControlComponent } from "./input-control/input-control.component";
import { PasswordControlComponent } from "./password-control/password-control.component";
import { SelectControlComponent } from "./select-control/select-control.component";
import { MatSelectModule } from "@angular/material/select";
import { MatButtonModule } from "@angular/material/button";
import { TableControlModule } from "./table-control/table-control.module";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { EmailControlComponent } from "./email-control/email-control.component";
import { NumberControlComponent } from "./number-control/number-control.component";
import { ToggleControlComponent } from "./toggle-control/toggle-control.component";
import { MatRippleModule } from "@angular/material/core";
import { DirectivesModule } from "../directives/directives.module";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TextareaControlComponent } from "./textarea-control/textarea-control.component";
import { FileUploadComponent } from "./file-upload/file-upload.component";
import { TextEditorModule } from "./text-editor/text-editor.module";
import { DateControlModule } from "./date-control/date-control.module";
import { PinControlComponent } from "./pin-control/pin-control.component";
import { NgbPopoverModule } from "@ng-bootstrap/ng-bootstrap";
import { MatPasswordStrengthModule } from "@angular-material-extensions/password-strength";
import { TinControlComponent } from './tin-control/tin-control.component';
import { VoterIdentificationNumberControlComponent } from './voter-identification-number-control/voter-identification-number-control.component';
import { PipesModule } from "../pipes/pipes.module";

@NgModule({
  declarations: [
    InputControlComponent,
    PasswordControlComponent,
    TextareaControlComponent,
    SelectControlComponent,
    EmailControlComponent,
    NumberControlComponent,
    ToggleControlComponent,
    FileUploadComponent,
    PinControlComponent,
    TinControlComponent,
    VoterIdentificationNumberControlComponent,

  ],

  imports: [
    CommonModule,
    TableControlModule,
    FormsModule,
    MatSelectModule,
    DateControlModule,
    MatButtonModule,
    NgxMatSelectSearchModule,
    MatRippleModule,
    DirectivesModule,
    MatTooltipModule,
    NgbPopoverModule,
    MatPasswordStrengthModule,
    PipesModule
    // TextEditorModule,
  ],
  exports: [
    FormsModule,
    TableControlModule,
    DateControlModule,
    InputControlComponent,
    PasswordControlComponent,
    SelectControlComponent,
    NgxMatSelectSearchModule,
    EmailControlComponent,
    NumberControlComponent,
    ToggleControlComponent,
    TextareaControlComponent,
    FileUploadComponent,
    PinControlComponent,
    TinControlComponent,
    VoterIdentificationNumberControlComponent,

    // TextEditorModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FormInputModule { }
