<div class="btn-combo">
    <div class="btn-text">{{label}}</div>
    <button mat-icon-button [matMenuTriggerFor]="actionMenu">
        <ion-icon name="chevron-down-outline"></ion-icon>
    </button>
</div>

<mat-menu #actionMenu="matMenu" xPosition="before">
    <ng-container *ngFor="let item of items">
        <ng-container *ngIf="item === 'Approve'">
            <button mat-menu-item (click)="doClick(item)" [parentId]="pageId"
                appAccess="ApproveOrgbtn">{{item}}</button>
        </ng-container>
        <ng-container *ngIf="item === 'Reject'">
            <button mat-menu-item (click)="doClick(item)" [parentId]="pageId" appAccess="RejectOrgbtn">{{item}}</button>
        </ng-container>
        <ng-container *ngIf="item !== 'Approve' && item !== 'Reject'">
            <button mat-menu-item (click)="doClick(item)">{{item}}</button>
        </ng-container>
    </ng-container>
</mat-menu>