import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class OnboardServicesService {
  initialData = {
    DDLMaritalStatus: [],
    DDLTribe: [],
    DDLNationality: [],
    DDLCommunity: [],
    DDLStatus: [],
    DDLGender: [],
    DDLProvince: [],
    DDLLocation: [],
    DDLIsland: [],
    DDLArea: [],
    DDLAddressType: [],
    DDLOrganisationName: [],
    DDLProductName: [],
    DDLOrganization: [],
    lstentDBHitOrganizationProduct: [],
    DDLPreApplicationName: [],
    DDLVilage: [],
    DDLTikina: [],
    DDLCategory: [],
    DDLOrganizationProduct: [],
    AllPreApplicationGroups: [],
    plistentCustomerApplicationData: []

  };
  customerApplicationId = 0;
  customerPolicyId = 0;
  customerId = 0;
  customerApplicationCustomerInfoId = 0;
  customerInfoId = 0;
  OrganizationRegitrationId = 0;
  userSerialId = 0;
  UserId = 0;
  memberId = 0;
  organizationBranchId = 0;
  organizationProductId = 0;
  preApplicationId = 0;
  applicationId = 0;
  orgCode = "";
  membertomemberdetailscreen = false;
  locationtomemberdetailscreen = false;
  selectedpremiumamount = "";
  selectedsuminsuredamount = "";
  iDocument = {
    documentId: 0,
    documentTypeId: 0,
    documentTypeValue: "",
    fileName: "",
    fileSizeKb: 0,
    fileType: "",
    relativePath: "",
    updateSeq: "",
    idoObjState: "",
    content: "",
    istrFileContent: "",
  };
  tabindexNo = 0;
  actionbtn = false;
  currentYear:any='';
  constructor() { }
}
