import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuardService } from "./common/services/auth/auth-guard.service";

const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./landing-page/landing-page.module").then(
        (m) => m.LandingPageModule
      ),
  },
  {
    path: "member/consent",
    loadChildren: () =>
      import("./consent/consent-screen/consent-screen.module").then(
        (m) => m.ConsentScreenModule
      ),
  },
  {
    path: "login/:id",
    loadChildren: () =>
      import("./start/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "register/:id",
    loadChildren: () =>
      import("./start/sign-up/sign-up.module").then((m) => m.SignUpModule),
  },
  {
    path: "set-password",
    loadChildren: () =>
      import("./start/set-password/set-password.module").then(
        (m) => m.SetPasswordModule
      ),
  },
  {
    path: "reset-password/:id",
    loadChildren: () =>
      import("./start/reset-forgot-password/reset-forgot-password.module").then(
        (m) => m.ResetForgotPasswordModule
      ),
  },
  {
    path: "reset-username/:id",
    loadChildren: () =>
      import("./start/reset-username/reset-username.module").then(
        (m) => m.ResetUsernameModule
      ),
  },
  {
    path: "forgot-password/:id",
    loadChildren: () =>
      import("./start/forgot-password/forgot-password.module").then(
        (m) => m.ForgotPasswordModule
      ),
  },
  {
    path: "forgot-username/:id",
    loadChildren: () =>
      import("./start/forgot-username/forgot-username.module").then(
        (m) => m.ForgotUsernameModule
      ),
  },
  {
    path: "otp-validation/:id",
    loadChildren: () =>
      import("./start/otp-validation/otp-validation.module").then(
        (m) => m.OtpValidationModule
      ),
  },
  {
    path: "otp-validation-password/:id",
    loadChildren: () =>
      import(
        "./start/otp-validation-password/otp-validation-password.module"
      ).then((m) => m.OtpValidationPasswordModule),
  },
  {
    path: "verfication-code",
    loadChildren: () =>
      import("./registration/verification-code/verification-code.module").then(
        (m) => m.VerificationCodeModule
      ),
  },
  {
    path: "reg-set-password",
    loadChildren: () =>
      import("./registration/reg-set-password/reg-set-password.module").then(
        (m) => m.RegSetPasswordModule
      ),
  },
  {
    path: "reg-set-pin",
    loadChildren: () =>
      import("./registration/reg-set-pin/reg-set-pin.module").then(
        (m) => m.RegSetPinModule
      ),
  },
  {
    path: "forgot-pin/:id",
    loadChildren: () =>
      import("./start/forgot-pin/forgot-pin.module").then(
        (m) => m.ForgotPinModule
      ),
  },
  {
    path: "otp-validation-pin/:id",
    loadChildren: () =>
      import("./start/otp-validation-pin/otp-validation-pin.module").then(
        (m) => m.OtpValidationPinModule
      ),
  },
  {
    path: "reset-pin/:id",
    loadChildren: () =>
      import("./start/reset-pin/reset-pin.module").then(
        (m) => m.ResetPinModule
      ),
  },
  {
    path: "home",
    loadChildren: () => import("./home/home.module").then((m) => m.HomeModule),
    // canActivate: [AuthGuardService],
  },
  { path: "**", redirectTo: "/" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: "legacy",
      scrollPositionRestoration: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
