import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-action-btn",
  templateUrl: "./action-btn.component.html",
  styleUrls: ["./action-btn.component.scss"],
})
export class ActionBtnComponent implements OnInit {
  pageId = "ORGSH";
  @Input() public label = "";
  @Input() public items = [];

  @Output("onClick") onClick: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void { }

  doClick(val) {
    this.onClick.emit(val);
  }
}
