<ng-container *ngIf="!loadingTrue">
    <div class="form-group">
        <form autocomplete="false">
            <div class="d-flex justify-content-between">
                <label class="pb-1">{{label}}<span class="text-danger px-1" *ngIf="star===true">*</span></label>
                <div class="pl15px text-danger lh18" *ngIf="value === '' && mandatory  && errorTrueNow">
                    <small>mandatory</small>
                </div>
            </div>
            <div class="d-flex align-items-center" [ngClass]="{'justify-center': alignLeft === false}">
                <ng-container *ngFor="let set of sets; let i = index">
                    <input autocomplete="new-password" class="form-control" [type]="inputtext" [(ngModel)]="set.value"
                        appPinNumberOnly limits="1" (input)="setValueFn()"
                        [class.border-warn]="set.value === '' && errorTrueNow" prev="{{set.previd}}"
                        next="{{set.nextid}}" id="{{set.id}}" (keyup)="keyevent($event)" name="set"
                        [ngModelOptions]="{standalone: true}">
                </ng-container>
                <ng-container *ngIf="passwordicon === 'true'">
                    <a mat-icon-button (click)="showtext()" tabIndex="-1">
                        <ng-container *ngIf="inputtext === 'password'">
                            <ion-icon name="eye"></ion-icon>
                        </ng-container>
                        <ng-container *ngIf="inputtext === 'text'">
                            <ion-icon name="eye-off"></ion-icon>
                        </ng-container>
                    </a>
                </ng-container>
            </div>
        </form>
        <div class="d-flex align-items-center" [ngClass]="{'justify-center': alignLeft === false}">
            <ng-container *ngIf="password === 'true'">
                <a mat-button class="mt10 i-btn w71" (click)="showtext()" tabIndex="-1">
                    <ng-container *ngIf="inputtext === 'password'">Show</ng-container>
                    <ng-container *ngIf="inputtext === 'text'">Hide</ng-container>
                </a>
            </ng-container>
            <ng-container *ngIf="clearbtn === 'true'">
                <a mat-button class="mt10 i-btn" (click)="clear()" tabIndex="-1">
                    Clear
                </a>
            </ng-container>
        </div>
    </div>
</ng-container>
<!-- <ng-container *ngIf="(i+1) < sets.length">
                        <div>-</div>
                    </ng-container> -->
<!-- <ng-container *ngIf="!loadingTrue">
    <div class="form-group">
        <form autocomplete="false">

            <div class="d-flex align-items-center" [ngClass]="{'justify-center': alignLeft === false}">
                <label for="" *ngIf="label !== ''">{{label}} <span class="text-danger"
                        *ngIf="mandatory && star">*</span></label>
                <div class="pl15px text-danger lh18 " *ngIf="value === '' && mandatory && star && errorTrueNow">
                    <small>Mandatory</small>
                </div>

            </div>
            <div class="d-flex align-items-center"
                [ngClass]="{'justify-center': alignLeft === false, 'pl28':alignLeft === false}">
                <ng-container *ngFor="let set of sets; let i = index">
                    <input autocomplete="new-password" class="form-control" [type]="inputtext" [(ngModel)]="set.value"
                        appPinNumberOnly limits="1" (input)="setValueFn()"
                        [class.border-warn]="set.value === '' && errorTrueNow" prev="{{set.previd}}"
                        next="{{set.nextid}}" id="{{set.id}}" (keyup)="keyevent($event)" name="set"
                        [ngModelOptions]="{standalone: true}" inputmode="numeric">

                </ng-container>
                <ng-container *ngIf="passwordicon === 'true'">
                    <a mat-icon-button class="pin" (click)="showtext()" tabIndex="-1">
                        <ng-container *ngIf="inputtext === 'password'">
                            <ion-icon name="eye-outline"></ion-icon>
                        </ng-container>
                        <ng-container *ngIf="inputtext === 'number'">
                            <ion-icon name="eye-off-outline"></ion-icon>
                        </ng-container>
                    </a>
                </ng-container>
            </div>
        </form>
        <div class="d-flex align-items-center" [ngClass]="{'justify-center': alignLeft === false}">
            <ng-container *ngIf="password === 'true'">
                <a mat-button class="mt10 i-btn w71" (click)="showtext()" tabIndex="-1">
                    <ng-container *ngIf="inputtext === 'password'">Show</ng-container>
                    <ng-container *ngIf="inputtext === 'number'">Hide</ng-container>
                </a>
            </ng-container>
            <ng-container *ngIf="clearbtn === 'true'">
                <a mat-button class="mt10 i-btn" (click)="clear()" tabIndex="-1">
                    Clear
                </a>
            </ng-container>
        </div>
    </div>
</ng-container> -->