import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { ValueAccessorBase } from "../value-accessor-base";

@Component({
  selector: "app-toggle-control",
  templateUrl: "./toggle-control.component.html",
  styleUrls: ["./toggle-control.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ToggleControlComponent,
      multi: true,
    },
  ],
})
export class ToggleControlComponent
  extends ValueAccessorBase<string>
  implements OnInit, AfterViewInit {
  checked = false;
  @Input() public trueValue: string = "";
  @Input() public falseValue: string = "";
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    super();
  }

  ngOnInit(): void { }

  ngAfterViewInit() {
    setTimeout(() => {
      // console.log(this.trueValue);
      this.value === this.trueValue
        ? (this.checked = true)
        : (this.checked = false);
    }, 400);
  }

  ngOnChanges(changes: SimpleChanges) { }

  onClick() {
    this.checked = !this.checked;
    this.checked
      ? (this.value = this.trueValue)
      : (this.value = this.falseValue);
    this.onChange.next(this.value);
  }
}
