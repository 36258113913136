import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableControlComponent } from './table-control.component';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EmptyStateModule } from '../../empty-state/empty-state.module';
import { DeleteAlertModule } from '../../alerts/delete-alert/delete-alert.module';
import { PipesModule } from '../../pipes/pipes.module';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [TableControlComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    MatSortModule,
    MatTooltipModule,
    EmptyStateModule,
    DeleteAlertModule,
    PipesModule,
    MatButtonModule,
  ],
  exports: [TableControlComponent],
})
export class TableControlModule {}
