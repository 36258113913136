<div class="form-group">
    <div class="d-flex align-item-center pb-1" *ngIf="label !== ''">
        <!-- <label for="" *ngIf="label !== ''">{{label}}</label> -->
        <label *ngIf="label !== ''" class="lh16">{{label}} <span class="text-danger" *ngIf="mandatory">*</span></label>
        <div class="ml-auto text-danger lh15" *ngIf="value === '' && errorTrueNow">
            <small>mandatory</small>
        </div>
        <ng-container *ngIf="editOption === 'true'">
            <div class="ml-auto">
                <a (click)="edit()">Edit</a>
            </div>
        </ng-container>
        <ng-container *ngIf="errorText !== ''">
            <div class="ml-auto">
                <small class="text-danger pl-3">{{errorText}}</small>
            </div>
        </ng-container>
    </div>
    <textarea appNoEmoji class="form-control {{class}}" [(ngModel)]="value" name="{{name}}"
        placeholder="{{placeholder}}" [ngClass]="{'border-danger': value === '' && errorTrueNow}"
        [disabled]="btnClickedNow" (blur)="blured()" #inputbox (keydown)="checkenter($event)" #selectInput></textarea>
</div>