import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { SwUpdate } from "@angular/service-worker";
import { CommonAlertComponent } from "../../alerts/common-alert/common-alert.component";

@Injectable({
  providedIn: "root",
})
export class UpdateService {
  constructor(private swUpdate: SwUpdate, public dialog: MatDialog) { }
  check() {
    this.swUpdate.available.subscribe((evt) => {
      // console.log(evt);
      this.openDialog();
    });
  }

  openDialog() {
    const dialogRef = this.dialog.open(CommonAlertComponent, {
      width: "450px",
      disableClose: true,
      data: {
        msg: "New version of application available. Would you like to update?",
        trueBtnText: "Update",
        trueBtnColor: "success",
        trueRaised: true,
        falseBtnText: "Later",
        falseBtnColor: "danger",
        showTrue: true,
        showFalse: true,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        window.location.reload();
      }
    });
  }
}
