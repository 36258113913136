<ng-container *ngIf="!loadingTrue">
    <ng-container *ngIf="tableData.data.length > 0">
        <table class="mat-table table-hover table-bordered sticky-table" matSort (matSortChange)="sortData($event)">
            <thead>
                <tr class="mat-header-row">
                    <ng-container *ngFor="let header of tableData.header">
                        <ng-container *ngIf="header.sort">
                            <th class="mat-header-cell sticky {{header.class ? header.class : ''}}"
                                [mat-sort-header]="header.keys[0]">
                                {{header.displayName}}</th>
                        </ng-container>
                        <ng-container *ngIf="!header.sort">
                            <th class="mat-header-cell sticky {{header.class ? header.class : ''}}">
                                {{header.displayName}}</th>
                        </ng-container>
                    </ng-container>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let data of tableData.data">
                    <tr class="mat-row">
                        <ng-container *ngFor="let header of tableData.header; let i = index">
                            <td class="mat-cell {{header.class ? header.class : ''}}"
                                [ngClass]="{'table-link': i === 0 && header.fclink !== 'true', 'py-0': header.keys[0] === 'action'}">
                                <ng-container *ngIf="i === 0">
                                    <span class="p-ab" (click)="doEdit(data)"></span>
                                </ng-container>
                                <ng-container *ngIf="header.keys[0] !== 'action'">
                                    <ng-container *ngFor="let keys of header.keys">
                                        <ng-container [ngTemplateOutlet]=" header.format ? trueBlock : elseBlock"
                                            [ngTemplateOutletContext]="{ data: data, keys: keys, header: header}">
                                        </ng-container>
                                        <!-- <ng-container *ngIf="header.format; else elseBlock">
                                            <ng-container *ngIf="header.format === 'date';  else elseBlock">
                                                {{data[keys] | appDate}}
                                            </ng-container>
                                        </ng-container> -->

                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="header.keys[0] === 'action'">
                                    <ng-container *ngFor="let btn of header.btn">
                                        <ng-container *ngIf="btn === 'edit'">
                                            <button mat-icon-button class="icon-only-btn" matTooltip="Edit">
                                                <ion-icon name="pencil-outline"></ion-icon>
                                            </button>
                                        </ng-container>
                                        <ng-container *ngIf="btn === 'delete'">
                                            <button mat-icon-button class="icon-only-btn ml-1" color="danger"
                                                matTooltip="Delete" (click)="openDelete(data)">
                                                <ion-icon name="trash-outline" color="danger"></ion-icon>
                                            </button>
                                        </ng-container>
                                        <ng-container *ngIf="btn === 'view'">
                                            <button mat-icon-button class="icon-only-btn ml-1" matTooltip="View"
                                                (click)="doView(data)">
                                                <ion-icon name="eye-outline"></ion-icon>
                                            </button>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </ng-container>
    <ng-container *ngIf="tableData.data.length === 0">
        <app-empty-state></app-empty-state>
    </ng-container>
</ng-container>

<ng-template #elseBlock let-data="data" let-keys="keys" let-header="header" let-index="index">
    <ng-container *ngIf="index === 0">
        <div (click)="doEdit(data)">{{data[keys]}}</div>
    </ng-container>
    <ng-container *ngIf="index !== 0">
        {{data[keys]}}
    </ng-container>
</ng-template>
<ng-template #trueBlock let-data="data" let-keys="keys" let-header="header" let-index="index">
    <ng-container [ngTemplateOutlet]="header.format === 'date' ? dateBlock : elseBlock"
        [ngTemplateOutletContext]="{ data: data, keys: keys, index:index}"></ng-container>
</ng-template>
<ng-template #dateBlock let-data="data" let-keys="keys" let-header="header" let-index="index">
    {{data[keys] | appDate}}
</ng-template>