import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValueAccessorBase } from '../value-accessor-base';

@Component({
  selector: 'app-vin-control',
  templateUrl: './voter-identification-number-control.component.html',
  styleUrls: ['./voter-identification-number-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: VoterIdentificationNumberControlComponent,
      multi: true,
    },
  ],
})
export class VoterIdentificationNumberControlComponent extends ValueAccessorBase<string>
  implements OnInit {
  xvalue: any = "";
  @Input() public label: string = '';
  @Input() public helptext: string = '';
  @Input() public name: string = '';
  @Input() public placeholder: string = '';
  @Input() public icononly = 'false';
  @Input() public search = 'false';
  @Input() public clearbtn = false;
  @Input() public mandatory = false;
  @Input() public upperCase = false;
  @Input() public maxlength = 1000;
  // tslint:disable-next-line: no-output-rename
  @Output('onblur') onblur: EventEmitter<any> = new EventEmitter();
  @Output('onenter') onenter: EventEmitter<any> = new EventEmitter();
  @Output('onClear') onClear: EventEmitter<any> = new EventEmitter();
  @ViewChild('inputbox', { static: false }) inputbox!: ElementRef;
  @ViewChild('selectInput') selectInput!: ElementRef;
  @Output() onsearchevent: EventEmitter<any> = new EventEmitter<any>();
  // tslint:disable-next-line: variable-name
  errorTrueNow = false;
  @Input()
  set errorTrue(errorTrue: boolean) {
    this.errorTrueNow = errorTrue || false;
  }
  get errorTrue() {
    return this.errorTrueNow;
  }
  constructor() {
    super();
  }

  btnClickedNow = false;
  @Input()
  set btnClicked(btnClicked: boolean) {
    this.btnClickedNow = btnClicked || false;
  }
  get btnClicked() {
    return this.btnClickedNow;
  }

  errorTextNow = '';
  @Input()
  set errorText(errorText: string) {
    this.errorTextNow = errorText || '';
  }
  get errorText() {
    return this.errorTextNow;
  }

  ngOnInit() {
    this.getValue().subscribe(async (xvalue) => {
      if (xvalue) {
        if (this.value) {

          this.xvalue = await this.checkVIN(this.value.toString());
        } else {
          this.xvalue = "";
        }
      }
    });
  }

  // tslint:disable-next-line: typedef
  blured() {
    this.onblur.next();
  }

  // tslint:disable-next-line: typedef
  setFocus() {
    this.inputbox.nativeElement.focus();
    this.inputbox.nativeElement.select();
  }

  checkenter(event: any) {
    if (event.key === 'Enter') {
      this.onenter.next();
    }
  }

  onFocus() {
    this.selectInput.nativeElement.focus();
  }

  onSearch() {
    this.onsearchevent.emit();
  }
  doClear() {
    this.value = '';
    this.xvalue = '';
    this.onClear.next();
  }


  @HostListener("input", ["$event"]) async onInputChange($event: any) {
    // // console.log($event);
    if ($event.inputType !== "deleteContentBackward") {
      let val = await this.checkVIN($event.target.value.toString());
      $event.target.value = val;
      this.xvalue = val;
    }
    let tempValue = this.xvalue.split('-').join("");
    // console.log(tempValue);
    if (tempValue.length === 12) {
      this.value = this.xvalue.split('-').join("");
      // this.change.emit(this.value);
    } else {
      this.value = "";
      // this.change.emit(this.value);
    }
  };
  // xx-xxxxx-x-x
  checkVIN(vin: any) {
    return new Promise((resolve) => {
      let val = vin.toString();
      val = val.replace(/\D/g, '');
      val = val.replace(/^(\d{4})/, '$1-');
      val = val.replace(/^(\d{4})-(\d{3})/, '$1-$2-');
      val = val.replace(/^(\d{4})-(\d{3})-(\d{5}).*/, '$1-$2-$3');
      resolve(val);
    })
  }
}
