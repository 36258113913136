<div class="form-group">
    <div class="d-flex align-item-center pb-1" *ngIf="label !== ''">
        <label for="" *ngIf="label !== ''" class="lh16">{{label}} <span class="text-danger"
                *ngIf="mandatory">*</span></label>
        <div class="ml-auto text-danger d-flex align-item-center lh15">
            <ng-container *ngIf="value !== '' && emailid.invalid && errorText !== '' && emailid.dirty">
                <div class="lh15"><small>{{errorText}}</small>
                </div>
            </ng-container>
            <ng-container *ngIf="value === '' && errorTrueNow">
                <div class="ml-2 lh15"><small>mandatory</small></div>
            </ng-container>
        </div>
        <!-- <div class="ml-auto text-danger" >
            
        </div> -->
    </div>
    <!-- <label for="">{{label}}</label> -->
    <div class="input-group">
        <input appNoEmoji autocomplete="none" type="email" class="form-control pr28px" [(ngModel)]="value"
            name="emailid" placeholder="{{placeholder}}"
            [ngClass]="{'border-danger': (emailid.invalid || value === '') && errorTrueNow}" [disabled]="btnClickedNow"
            errorTextNow="(emailid.invalid || value === '') && errorTrueNow" appLowerCase #emailid="ngModel"
            [email]="true">
        <div class="input-group-append in-1" *ngIf="value && !btnClickedNow">
            <a mat-button class="i-btn" (click)="value = ''" matTooltip="Clear" matTooltipPosition="right"
                tabIndex="-1">
                <!-- <mat-icon>clear</mat-icon> -->
                <ion-icon name="close-outline"></ion-icon>
            </a>
        </div>
    </div>
</div>