export class AppConfig {
  public static roleEnabled = true;
  public static routeGuardEnabled = true;
  public static baseCurrency = "USD";
  public static dbname = "__digital-onboarding";
  // public static dateServerFormat = "dd-MM-yyyy";
  // public static dateViewFormat = "dd MMM yyyy";
  public static pageSizeOptions = [15, 50, 100];
  public static EmailPattern = "^S+@S+$";
}
