<router-outlet></router-outlet>
<div class="launch-box" *ngIf="data.showLaunchModal">
    <div class="coverUp" (click)="explore()"></div>
    <div class="launch-modal">
        <div class="launch-body">
            <div class="flex-1 d-flex align-items-center justify-content-center">
                <div>
                    <div class="brand">
                        <div class="logo">
                            <img src="assets/logo-alternative.svg" alt="" class="white-logo">
                        </div>
                    </div>
                    <div class="h306 animate__animated" #animation>
                        <ng-container *ngIf="successMessage">
                            <div class="pt-5"></div>
                            <h1>{{successMessageText}}</h1>
                        </ng-container>
                        <ng-container *ngIf="!successMessage">
                            <h2 *ngIf="!launched" class="pt-3">Application Launch By</h2>
                            <h2 class="pt-3 success-t" *ngIf="launched">Application Successfully Launched By</h2>
                            <h1>H.E. Mr.Jonathan Curr</h1>
                            <h3 class="pt-1">New Zealand High Commissioner</h3>
                            <ng-container *ngIf="!launched">
                                <h4>on 25 Aug 2021</h4>
                            </ng-container>
                            <ng-container *ngIf="launched">
                                <h4>on {{launchDateTime | appDate: appSetting.environment.dateViewFormat}}</h4>
                            </ng-container>
                            <div class="d-flex align-items-center justify-content-center h82">
                                <ng-container *ngIf="btnLoading">
                                    <app-spinner></app-spinner>
                                </ng-container>
                                <ng-container *ngIf="!btnLoading">
                                    <ng-container *ngIf="!launched && !showLaunchBtn">
                                        <div class="form-group w50">
                                            <input type="text" class="form-control" maxlength="1" #input1
                                                [(ngModel)]="launchcode.launchcode1" appNumberOnly (input)="doInput(1)"
                                                (keyup)="checkdelete($event, 1)">
                                        </div>
                                        <div class="form-group w50">
                                            <input type="text" class="form-control" maxlength="1" #input2
                                                [(ngModel)]="launchcode.launchcode2" appNumberOnly (input)="doInput(2)"
                                                (keyup)="checkdelete($event, 2)">
                                        </div>
                                        <div class="form-group w50">
                                            <input type="text" class="form-control" maxlength="1" #input3
                                                [(ngModel)]="launchcode.launchcode3" appNumberOnly (input)="doInput(3)"
                                                (keyup)="checkdelete($event, 3)">
                                        </div>
                                        <div class="form-group w50">
                                            <input type="text" class="form-control" maxlength="1" #input4
                                                [(ngModel)]="launchcode.launchcode4" appNumberOnly (input)="doInput(4)"
                                                (keyup)="checkdelete($event, 4)">
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="!launched && showLaunchBtn">
                                        <button mat-raised-button class="launch-btn" (click)="launchNow()">Launch
                                            Now</button>
                                    </ng-container>
                                    <ng-container *ngIf="launched">
                                        <button mat-button class="launch-btn-grey w170" (click)="explore()">Explore
                                            iOnboard</button>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="launch-footer">
                <div class="sponser-image">
                    <div class="s-img-box">
                        <img src="assets/sponser/5.png" alt="">
                    </div>

                    <div class="s-img-box">
                        <img src="assets/sponser/Picture3.png" alt="">
                    </div>
                    <div class="s-img-box">
                        <img src="assets/sponser/4.png" alt="">
                    </div>
                </div>
                <div class="sponser-image bg-white py-3">
                    <div class="s-img-box">
                        <img src="assets/sponser/6.png" alt="">
                    </div>
                    <div class="s-img-box" style="width: 136px;">
                        <img src="assets/sponser/2.png" alt="">
                    </div>

                    <div class="s-img-box" style="width: 144px;">
                        <img src="assets/sponser/1.png" alt="">
                    </div>

                    <div class="s-img-box" style="width:98.68px; min-width: 95px;">
                        <img src="assets/sponser/3.png" alt="">
                    </div>
                    <div class="s-img-box" style="width:191.5px;">
                        <img src="assets/sponser/luxembourg.png" alt="">
                    </div>


                </div>

            </div>
        </div>
    </div>
    <div class="closeBtn" *ngIf="launched">
        <button mat-icon-button (click)="explore()">
            <ion-icon name="close-outline"></ion-icon>
        </button>
    </div>
</div>