<ng-container *ngIf="data.message.errorMessage.length > 0">
    <div class="footer-msg-box">
        <ng-container [ngTemplateOutlet]="messageBar"></ng-container>
    </div>

    <ng-template #messageBar>
        <div class="bg-toolbar" matRipple (click)="expandMessageBox(0)">
        </div>
        <div class="title-1">Message</div>
        <div class="p-3"></div>
        <ng-container>
            <ng-container *ngIf="(data.message.errorMessage | messageFilter : 0).length > 0">
                <div class="error-title" matRipple (click)="expandMessageBox(0)">Error ({{(data.message.errorMessage |
                    messageFilter: 0).length}})</div>
            </ng-container>
            <ng-container *ngIf="(data.message.errorMessage | messageFilter : 1).length > 0">
                <div class="warning-title" matRipple (click)="expandMessageBox(1)">Warning ({{(data.message.errorMessage
                    | messageFilter: 1).length}})</div>
            </ng-container>
            <ng-container *ngIf="(data.message.errorMessage | messageFilter : 2).length > 0">
                <div class="information-title" matRipple (click)="expandMessageBox(2)">Information
                    ({{(data.message.errorMessage | messageFilter: 2).length}})</div>
            </ng-container>
        </ng-container>
        <div class="ml-auto">
            <button mat-icon-button class="text-center expand-btn" [ngClass]="{'expanded': isExpanded}"
                (click)="expandMessageBox(0)">
                <ion-icon name="chevron-up-outline"></ion-icon>
            </button>
            <button mat-icon-button class="text-center expand-btn ml-3" (click)="closeErrorMessageBox()">
                <ion-icon name="close-outline"></ion-icon>
            </button>
        </div>
    </ng-template>

    <ng-template #messageExpanded>
        <div class="message-cover">
            <div class="footer-msg-box">
                <ng-container [ngTemplateOutlet]="messageBar"></ng-container>
            </div>
            <div class="msg-portal-box bg-white">
                <div class="message-body" [ngClass]="{'closing' : closing}">
                    <div class="message-box">
                        <ul class="error-message" *ngIf="errorMessage">
                            <li class="d-flex align-items-center"
                                *ngFor="let errMsg of data.message.errorMessage | messageFilter : 0">
                                <ion-icon src="assets/svg/triangle.svg" class="mr-2"></ion-icon>
                                <div>{{errMsg.msgDescription}}</div>
                            </li>
                        </ul>
                        <ul class="warning-message" *ngIf="warningMessage">
                            <li class="d-flex align-items-center"
                                *ngFor="let errMsg of data.message.errorMessage | messageFilter : 1">
                                <!-- <ion-icon  ></ion-icon> -->
                                <ion-icon name="warning-outline" class="mr-2 text-warning"></ion-icon>
                                <div>{{errMsg.msgDescription}}</div>
                            </li>
                        </ul>
                        <ul class="info-message" *ngIf="infoMessage">
                            <li class="d-flex align-items-center"
                                *ngFor="let errMsg of data.message.errorMessage | messageFilter : 2">
                                <!-- <ion-icon src="assets/svg/triangle.svg" class="mr-2"></ion-icon> -->
                                <ion-icon name="information-circle-outline" class="mr-2"></ion-icon>
                                <div>{{errMsg.msgDescription}}</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</ng-container>