import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SearchBoxComponent } from "./search-box.component";
import { MatButtonModule } from "@angular/material/button";
import { MatTooltipModule } from "@angular/material/tooltip";
import { OverlayModule } from "@angular/cdk/overlay";
import { DirectivesModule } from "../directives/directives.module";
import { FormInputModule } from "../form-input/form-input.module";
import { FormsModule } from "@angular/forms";
import { MatRippleModule } from "@angular/material/core";

@NgModule({
  declarations: [SearchBoxComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    MatButtonModule,
    MatTooltipModule,
    OverlayModule,
    DirectivesModule,
    FormsModule,
    FormInputModule,
    MatRippleModule,
  ],
  exports: [SearchBoxComponent],
})
export class SearchBoxModule {}
