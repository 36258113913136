import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { OnboardServicesService } from 'src/app/home/onboard-services.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  public history: string[] = []
  screenname: any = '';
  constructor(public router: Router,
    public service: OnboardServicesService,
    public location: Location) {

  }
  public startSaveHistory(): void {

    // this.screenname = this.service.membertomemberdetailscreen;

    this.router.events.subscribe((event) => {
      if (event instanceof RoutesRecognized) {
        // // console.log('previous url', event[0].urlAfterRedirects);
      }

      if (event instanceof NavigationEnd) {
        // // console.log(event);
        this.history.push(event.urlAfterRedirects)
      }
    })

  }
  public getHistory(): string[] {

    return this.history;

  }
  public goBack(): void {


    this.history.pop();
    if (this.history.length > 0) {
      this.location.back();
      // console.log(this.location)
      // this.getPreviousUrl();
    }
    else {
      this.router.navigateByUrl("/")
    }
  }
  public getPreviousUrl(): string {
    debugger
    if (this.history.length > 0) {
      // this.screenname = this.history[0].substring(6, 30);
      // console.log(this.history);
      return this.history[this.history.length - 2];

    }
    return '';
  }
  public getScreenname() {
    this.getPreviousUrl();
    // if (this.screenname === 'location-verification') {
    //   this.screenname = "Location Verification";
    // }

    // else {
    //   this.screenname = "member";
    // }

    // return this.screenname;
  }

}
